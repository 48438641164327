@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Odibee+Sans&family=Open+Sans&family=Orbitron&family=Roboto:wght@100;400;700&family=Work+Sans:wght@300&display=swap');

.App {
  min-height: 100vh;
  display: flex;
  background-image: url('./background.png');
  background-size: cover;
  background-position: center;
  color: black;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.281);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
